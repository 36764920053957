<template>
  <div class="w-full md:w-1/2 ml-4 my-4">
    <div
      class="rounded border bg-gray-200 overflow-hidden divide-y divide-gray-200 sm:divide-y-0 sm:grid sm:grid-cols-2 sm:gap-px justify-items-end"
    >
      <div
        class="relative sm:flex sm:flex-col w-full bg-white p-6"
        v-for="(role, index) in roles"
        :key="`role-${index}`"
      >
        <div class="flex flex-col">
          <h3 class="text-lg font-medium">
            {{ role.role_name }}
          </h3>
          <div class="mt-2 text-xs items-start sm:overflow-y-scroll sm:h-32 sm:max-h-32">
            <div class="flex flex-wrap space-y-1">
              <span></span>
              <span
                class="border border-indigo-300 mr-1 bg-indigo-100 px-1 text-indigo-800 rounded font-medium flex-shrink-0"
                v-for="(permission, pindex) in role.permissions"
                :key="`perm-${index}-${pindex}`"
                >{{ permission.perm_name.replace('_', ' ') }}</span
              >
            </div>
          </div>
        </div>
        <a
          href="#"
          @click.prevent="setModal({ show: true, component: 'new-group', data: role })"
          class="absolute top-6 right-6 text-gray-300 hover:text-gray-400"
          aria-hidden="true"
        >
          <svg
            class="h-6 w-6"
            xmlns="http://www.w3.org/2000/svg"
            fill="currentColor"
            viewBox="0 0 24 24"
          >
            <path
              d="M20 4h1a1 1 0 00-1-1v1zm-1 12a1 1 0 102 0h-2zM8 3a1 1 0 000 2V3zM3.293 19.293a1 1 0 101.414 1.414l-1.414-1.414zM19 4v12h2V4h-2zm1-1H8v2h12V3zm-.707.293l-16 16 1.414 1.414 16-16-1.414-1.414z"
            />
          </svg>
        </a>

        <button
          v-if="can('delete_group')"
          @click.prevent="
            setModal({
              show: true,
              component: 'delete',
              data: role,
              notifier: 'reload-groups',
            })
          "
          class="rounded bg-red-600 text-white px-3 py-1 text-xs self-end mt-8"
        >
          DELETE
        </button>
      </div>
    </div>
  </div>
</template>

<script>
// import _ from 'lodash'
import http from '@/utils/http'

// import DesktopStats from '@/components/DesktopStats'
// import MobileStats from '@/components/MobileStats'

// import DropdownActions from '@/components/DropdownActions'

export default {
  name: 'GroupsAndPermissions',
  props: [],
  components: {
    /* DesktopStats, MobileStats, DropdownActions */
  },
  data() {
    return {
      roles: [],
      context: [],
      stats: [
        {
          short: 'OK',
          label: 'Working Instances',
          class: 'bg-green-600',
          count: 'Total Working 145',
        },
      ],
      actions: {
        label: 'Actions',
        options: [
          {
            label: 'Update Role',
            icon: '&#x1F4AC;',
            event: 'update-user',
          },
          {
            label: 'Delete Role',
            icon: '&#x21A9;&#xFE0F;',
            event: 'delete-user',
          },
        ],
      },
    }
  },
  mounted() {
    this.init()

    this.setNewButton({ label: 'Group', component: 'new-group', endpoint: 'roles' })
    this.setPage({ title: 'Groups' })

    this.$bus.$on('reload-groups', this.init)
  },
  beforeDestroy() {
    this.$bus.$off('reload-groups', this.init)
  },
  methods: {
    init() {
      http
        .get(`/roles`)
        .then(response => {
          if (response.status === 200) {
            this.roles = response.data
            this.context = [...Array.from(Object.keys(response.data), () => false)]
          }
        })
        .catch(err => {
          console.log(err)
        })
    },
    destroy(id) {
      http.delete(`/roles/${id}`).then(() => {
        this.setModal({ show: false, notifier: 'reload-groups' })
        this.init()
      })
    },
    close() {
      this.context = false
    },
  },
}
</script>

<style></style>
